@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.marquee-desktop {
  display: block;
}

.marquee-mobile {
  display: none;
}

@media (max-width: 768px) {
  .marquee-desktop {
    display: none;
  }
  .marquee-mobile {
    display: block;
    transform: scale(1.2); /* Adjust the scale factor as needed */
  }
}
.girlies-desktop {
  display: block;
}

.girlies-mobile {
  display: none;
}

@media (max-width: 768px) {
  .girlies-desktop {
    display: none;
  }
  .girlies-mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .about-mobile {
    
    padding-left: 20%;
    align-items: center;
  }
}